
<template>
  <div>
    <div class="card">
      <div class="card-header">Insights</div>
      <div class="card-body" style="background-color: #f9f9f9">
        <div class="row">
          <div class="col-lg-12">
            <CCard>
              <CCardHeader> DAILY/WEEK/MONTH leads number </CCardHeader>
            </CCard>
            <div class="row">
              <div class="col-lg-12">
                <semipolar-spinner
                  :animation-duration="2000"
                  :size="100"
                  color="blue"
                  v-if="graph_dailyLeadsDataLoading"
                  >Spinner</semipolar-spinner
                >
                <div
                  class="chart-wrapper pt-3"
                  v-if="graph_dailyLeadsDataLoading == false"
                >
                  <highcharts
                    class="chart"
                    :options="graph_dailyLeadsData"
                  ></highcharts>
                </div>
              </div>
              <div class="col-lg-12">
                <semipolar-spinner
                  :animation-duration="2000"
                  :size="100"
                  color="blue"
                  v-if="graph_weeklyLeadsDataLoading"
                  >Spinner</semipolar-spinner
                >
                <div
                  class="chart-wrapper pt-3"
                  v-if="graph_weeklyLeadsDataLoading == false"
                >
                  <highcharts
                    class="chart"
                    :options="graph_weeklyLeadsData"
                  ></highcharts>
                </div>
              </div>
              <div class="col-lg-12">
                <semipolar-spinner
                  :animation-duration="2000"
                  :size="100"
                  color="blue"
                  v-if="graph_monthlyLeadsDataLoading"
                  >Spinner</semipolar-spinner
                >
                <div
                  class="chart-wrapper pt-3"
                  v-if="graph_monthlyLeadsDataLoading == false"
                >
                  <highcharts
                    class="chart"
                    :options="graph_monthlyLeadsData"
                  ></highcharts>
                </div>
              </div>
            </div>
          </div>

          <div class="col-lg-6 mt-3">
            <div class="row">
              <div class="col-lg-12">
                <semipolar-spinner
                  :animation-duration="2000"
                  :size="100"
                  color="blue"
                  v-if="graph_callStatusLeadsDataLoading"
                  >Spinner</semipolar-spinner
                >
                <div
                  class="chart-wrapper pt-3"
                  v-if="graph_callStatusLeadsDataLoading == false"
                >
                  <highcharts
                    class="chart"
                    :options="callStatusPieChart"
                  ></highcharts>
                </div>
              </div>
            </div>
          </div>

          <div class="col-lg-6 mt-3">
            <div class="row">
              <div class="col-lg-12">
                <div class="chart-wrapper pt-3">
                  <semipolar-spinner
                    :animation-duration="2000"
                    :size="100"
                    color="blue"
                    v-if="graph_lostReasonNoDataLoading"
                    >Spinner</semipolar-spinner
                  >
                  <template v-if="graph_lostReasonNoDataLoading == false">
                    <highcharts
                      class="chart"
                      :options="graph_lostReasonData"
                    ></highcharts>
                  </template>
                </div>
              </div>
            </div>
          </div>
        </div>

        <!-- Business Leads -->
        <div class="row">
          <div class="col-lg-12 mt-5">
            <CCard>
              <CCardHeader> Social Leads </CCardHeader>
            </CCard>
          </div>
          <div class="col-lg-3">
            <div class="social-wrapper">
              <div class="head p-3 pt-4 pb-4">
                <CIcon :content="$options.fb" />
              </div>
              <div class="pt-1 pb-1">
                <div class="body p-2">
                  <semipolar-spinner
                    :animation-duration="2000"
                    :size="50"
                    color="white"
                    v-if="fbleads_loading"
                    >Spinner</semipolar-spinner
                  >
                  <template v-if="fbleads_loading == false">
                    <div>
                      <p class="title mb-0">
                        <b>{{ fbleads_data.count }}</b>
                      </p>
                      <p class="sub text-uppercase mb-0">Leads</p>
                    </div>
                    <div class="line"></div>
                    <div>
                      <p class="title mb-0">
                        <b>{{ fbleads_data.feeds }}</b>
                      </p>
                      <p class="sub text-uppercase mb-0">Aptos</p>
                    </div>
                  </template>
                </div>
              </div>
            </div>
          </div>
          <div class="col-lg-3">
            <div class="social-wrapper">
              <div class="head p-3 pt-4 pb-4">
                <CIcon :content="$options.ln" />
              </div>
              <div class="pt-1 pb-1">
                <div class="body p-2">
                  <semipolar-spinner
                    :animation-duration="2000"
                    :size="50"
                    color="white"
                    v-if="lnleads_loading"
                    >Spinner</semipolar-spinner
                  >
                  <template v-if="lnleads_loading == false">
                    <div>
                      <p class="title mb-0">
                        <b>{{ lnleads_data.count }}</b>
                      </p>
                      <p class="sub text-uppercase mb-0">Leads</p>
                    </div>
                    <div class="line"></div>
                    <div>
                      <p class="title mb-0">
                        <b>{{ lnleads_data.feeds }}</b>
                      </p>
                      <p class="sub text-uppercase mb-0">Aptos</p>
                    </div>
                  </template>
                </div>
              </div>
            </div>
          </div>
          <div class="col-lg-3">
            <div class="social-wrapper">
              <div class="head p-3 pt-4 pb-4">
                <CIcon :content="$options.ga" />
              </div>
              <div class="pt-1 pb-1">
                <div class="body p-2">
                  <semipolar-spinner
                    :animation-duration="2000"
                    :size="50"
                    color="white"
                    v-if="galeads_loading"
                    >Spinner</semipolar-spinner
                  >
                  <template v-if="galeads_loading == false">
                    <div>
                      <p class="title mb-0">
                        <b>{{ galeads_data.count }}</b>
                      </p>
                      <p class="sub text-uppercase mb-0">Leads</p>
                    </div>
                    <div class="line"></div>
                    <div>
                      <p class="title mb-0">
                        <b>{{ galeads_data.feeds }}</b>
                      </p>
                      <p class="sub text-uppercase mb-0">Aptos</p>
                    </div>
                  </template>
                </div>
              </div>
            </div>
          </div>
          <div class="col-lg-3">
            <div class="social-wrapper">
              <div class="head p-3 pt-4 pb-4">
                <CIcon :content="$options.dv360" /><br />
                <span>Display & Video 360</span>
              </div>
              <div class="pt-1 pb-1">
                <div class="body p-2">
                  <semipolar-spinner
                    :animation-duration="2000"
                    :size="50"
                    color="white"
                    v-if="dv360leads_loading"
                    >Spinner</semipolar-spinner
                  >
                  <template v-if="dv360leads_loading == false">
                    <div>
                      <p class="title mb-0">
                        <b>{{ dv360leads_data.count }}</b>
                      </p>
                      <p class="sub text-uppercase mb-0">Leads</p>
                    </div>
                    <div class="line"></div>
                    <div>
                      <p class="title mb-0">
                        <b>{{ dv360leads_data.feeds }}</b>
                      </p>
                      <p class="sub text-uppercase mb-0">Aptos</p>
                    </div>
                  </template>
                </div>
              </div>
            </div>
          </div>
        </div>

        <!-- Data Leads -->
        <div class="row">
          <div class="col-lg-12 mt-5">
            <CCard>
              <CCardHeader> Data Table </CCardHeader>
            </CCard>
          </div>
          <div class="col-lg-12">
            <semipolar-spinner
              :animation-duration="2000"
              :size="80"
              color="blue"
              v-if="datatableLoads"
              >Spinner</semipolar-spinner
            >
            <template v-if="datatableLoads == false">
              <CDataTable
                :hover="true"
                :striped="true"
                :border="true"
                :items="items"
                :fields="fields"
                :items-per-page="10"
                pagination
              >
              </CDataTable>
            </template>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {
  cilPlus,
  cibFacebookF,
  cibLinkedin,
  cibGoogleAds,
  cibGooglePlay,
  cibGoogle,
} from "@coreui/icons";
import { Chart } from "highcharts-vue";
import { SemipolarSpinner } from "epic-spinners";
import helper from "../helpers/index";
export default {
  name: "Insights",
  components: {
    SemipolarSpinner,
    highcharts: Chart,
  },
  plus: cilPlus,
  fb: cibFacebookF,
  ln: cibLinkedin,
  ga: cibGoogleAds,
  dv360: cibGoogle,
  data() {
    return {
      graph_dailyLeadsDataLoading: null,
      graph_dailyLeadsData: {
        chart: {
          zoomType: "xy",
        },
        title: {
          text: "Daily Leads",
        },
        xAxis: [
          {
            categories: [],
            crosshair: true,
          },
        ],
        yAxis: [
          {
            // Primary yAxis
            labels: {
              format: "{value}",
            },
            title: {
              text: "Apto / No apto",
            },
            opposite: false,
          },
          // {
          //   // Secondary yAxis
          //   gridLineWidth: 0,
          //   title: {
          //     text: "Daily Leads",
          //   },
          //   labels: {
          //     format: "{value}",
          //   },
          // },
        ],
        credits: false,
        tooltip: {
          shared: true,
        },
        legend: {
          layout: "horizontal",
          align: "center",
          // layout: "vertical",
          // align: "left",
          // x: 120,
          // verticalAlign: "top",
          // y: 100,
          // floating: true,
          backgroundColor: "rgba(255,255,255,0.25)",
        },
        series: [
          {
            name: "Apto",
            type: "column",
            color: "#FB963E",
            data: [],
            tooltip: {
              valueSuffix: " ",
            },
          },
          {
            name: "No apto",
            type: "column",
            color: "#595959",
            data: [],
            tooltip: {
              valueSuffix: " ",
            },
          },
        ],
      },
      graph_weeklyLeadsDataLoading: null,
      graph_weeklyLeadsData: {
        chart: {
          zoomType: "xy",
        },
        title: {
          text: "Weekly Leads",
        },
        xAxis: [
          {
            categories: [],
            crosshair: true,
          },
        ],
        yAxis: [
          {
            // Primary yAxis
            labels: {
              format: "{value}",
            },
            title: {
              text: "Apto / No apto",
            },
            opposite: false,
          },
          // {
          //   // Secondary yAxis
          //   gridLineWidth: 0,
          //   title: {
          //     text: "Daily Leads",
          //   },
          //   labels: {
          //     format: "{value}",
          //   },
          // },
        ],
        credits: false,
        tooltip: {
          shared: true,
        },
        legend: {
          layout: "horizontal",
          align: "center",
          // layout: "vertical",
          // align: "left",
          // x: 120,
          // verticalAlign: "top",
          // y: 100,
          // floating: true,
          backgroundColor: "rgba(255,255,255,0.25)",
        },
        series: [
          {
            name: "Apto",
            type: "column",
            color: "#FB963E",
            data: [],
            tooltip: {
              valueSuffix: " ",
            },
          },
          {
            name: "No apto",
            type: "column",
            color: "#595959",
            data: [],
            tooltip: {
              valueSuffix: " ",
            },
          },
        ],
      },
      graph_monthlyLeadsDataLoading: null,
      graph_monthlyLeadsData: {
        chart: {
          zoomType: "xy",
        },
        title: {
          text: "Monthly Leads",
        },
        xAxis: [
          {
            categories: [],
            crosshair: true,
          },
        ],
        yAxis: [
          {
            // Primary yAxis
            labels: {
              format: "{value}",
            },
            title: {
              text: "Apto / No apto",
            },
            opposite: false,
          },
          // {
          //   // Secondary yAxis
          //   gridLineWidth: 0,
          //   title: {
          //     text: "Daily Leads",
          //   },
          //   labels: {
          //     format: "{value}",
          //   },
          // },
        ],
        credits: false,
        tooltip: {
          shared: true,
        },
        legend: {
          layout: "horizontal",
          align: "center",
          // layout: "vertical",
          // align: "left",
          // x: 120,
          // verticalAlign: "top",
          // y: 100,
          // floating: true,
          backgroundColor: "rgba(255,255,255,0.25)",
        },
        series: [
          {
            name: "Apto",
            type: "column",
            color: "#FB963E",
            data: [],
            tooltip: {
              valueSuffix: " ",
            },
          },
          {
            name: "No apto",
            type: "column",
            color: "#595959",
            data: [],
            tooltip: {
              valueSuffix: " ",
            },
          },
        ],
      },
      graph_lostReasonNoDataLoading: null,
      graph_lostReasonData: {
        chart: {
          type: "column",
          zoomType: "xy",
        },
        title: {
          text: "Lost Reason Numbers",
        },
        xAxis: [
          {
            categories: [
              "Languaje",
              "Practices",
              "Presential",
              "Schedule",
              "Title",
            ],
            crosshair: true,
          },
        ],
        yAxis: [
          {
            // Primary yAxis
            labels: {
              format: "{value}",
            },
            title: {
              text: "Nos",
            },
            opposite: false,
          },
          // {
          //   // Secondary yAxis
          //   gridLineWidth: 0,
          //   title: {
          //     text: "Daily Leads",
          //   },
          //   labels: {
          //     format: "{value}",
          //   },
          // },
        ],
        credits: false,
        tooltip: {
          shared: true,
        },
        legend: {
          layout: "horizontal",
          align: "center",
          // layout: "vertical",
          // align: "left",
          // x: 120,
          // verticalAlign: "top",
          // y: 100,
          // floating: true,
          backgroundColor: "rgba(255,255,255,0.25)",
        },
        plotOptions: {
          column: {
            pointPadding: 0.2,
            borderWidth: 0,
            colorByPoint: true,
          },
        },
        colors: ["#695ef1", "#a45ef1", "#f1a45e", "#47454d", "#94de7b"],
        series: [
          {
            name: "Lost Reason Numbers",
            color: "red",
            data: [
              // 25, 13, 2, 4, 5
            ],
            tooltip: {
              valueSuffix: " ",
            },
          },
        ],
      },
      graph_callStatusLeadsDataLoading: null,
      callStatusPieChart: {
        chart: {
          type: "pie",
          zoomType: "xy",
        },
        title: {
          text: "Call Status Numbers",
        },
        tooltip: {
          pointFormat: "{series.name}: <b>{point.percentage:.1f}%</b>",
        },
        accessibility: {
          point: {
            valueSuffix: "%",
          },
        },
        plotOptions: {
          pie: {
            allowPointSelect: true,
            cursor: "pointer",
            dataLabels: {
              enabled: true,
              format: "<b>{point.name}</b>: {point.percentage:.1f} %",
              connectorColor: "blue",
            },
          },
        },
        credits: false,
        tooltip: {
          shared: true,
        },
        legend: {
          layout: "horizontal",
          align: "center",
          // layout: "vertical",
          // align: "left",
          // x: 120,
          // verticalAlign: "top",
          // y: 100,
          // floating: true,
          backgroundColor: "rgba(255,255,255,0.25)",
        },
        series: [
          {
            name: "Call Status Leads",
            innerSize: "40%",
            data: [
              // { name: "Data 1", y: 65, color: "#f26083" },
              // { name: "Data 2", y: 15, color: "#38a4e8" },
              // { name: "Data 3", y: 20, color: "#ffcc62" },
            ],
          },
        ],
      },

      // Social Leads Section Starts
      fbleads_loading: null,
      fbleads_data: {
        count: 0,
        feeds: 0,
      },
      lnleads_loading: null,
      lnleads_data: {
        count: 0,
        feeds: 0,
      },
      galeads_loading: null,
      galeads_data: {
        count: 0,
        feeds: 0,
      },
      dv360leads_loading: null,
      dv360leads_data: {
        count: 0,
        feeds: 0,
      },
      // Ends
      datatableLoads: null,
      items: [
        // {
        //   degree: "MASTER EN BIG DATA TECNOLOGIA Y ANALITICA AVANZADA",
        //   email1_date: 2,
        //   call_done_date: 2,
        //   email2_date: 1,
        //   email3_date: 1,
        // }
      ],
      fields: [
        {
          key: "degree",
          label: "GRADO",
        },
        {
          key: "email1_date",
          label: "EMAIL 1",
        },
        {
          key: "call_done_date",
          label: "CALL 1",
        },
        {
          key: "email2_date",
          label: "EMAIL 2",
        },
        {
          key: "email3_date",
          label: "EMAIL 3",
        },
      ],
    };
  },
  created() {
    this.loadDailyLeads();
    this.loadWeeklyLeads();
    this.loadMonthlyLeads();
    this.loadCallStatusLeads();
    // Social Leads
    this.loadFacebookLeads();
    this.loadLinkedInLeads();
    this.loadGoogleAdsLeads();
    this.loadDev360Leads();
    // Ends
    this.loadLostReasonNo();
    this.loadDatatable();
  },
  methods: {
    loadDailyLeads() {
      this.graph_dailyLeadsDataLoading = true;
      this.$store.dispatch("a_DailyLeadsLoad", {}).then(() => {
        const payload = this.$store.getters.g_dailyLeadsData;
        if (payload.length) {
          let dateArr = this.$_.uniq(
            payload.reduce((acc, item) => [...acc, item["create_date:day"]], [])
          );

          let aptoArr = [];
          let naptoArr = [];
          // dateArr = dateArr.splice(0, 2);
          dateArr.forEach((date) => {
            let apto = 0;
            let napto = 0;
            payload.forEach((item) => {
              if (item["create_date:day"] == date) {
                if (item["apto"] == 1) {
                  apto = apto + (item.__count - 0);
                } else if (item["apto"] == 2) {
                  napto = napto + (item.__count - 0);
                }
              }
            });
            aptoArr.push(apto);
            naptoArr.push(napto);
            this.graph_dailyLeadsDataLoading = false;
          });
          // let dateArrNames =
          this.graph_dailyLeadsData.xAxis[0].categories = [...dateArr];
          this.graph_dailyLeadsData.series[0].data = [...aptoArr];
          this.graph_dailyLeadsData.series[1].data = [...naptoArr];
          // console.log("data", "leads :::: ", dateArr);
        } else {
          this.graph_dailyLeadsDataLoading = false;
        }
      });
    },
    loadWeeklyLeads() {
      this.graph_weeklyLeadsDataLoading = true;
      this.$store.dispatch("a_WeeklyLeadsLoad", {}).then(() => {
        const payload = this.$store.getters.g_weeklyLeadsData;
        if (payload.length) {
          let dateArr = this.$_.uniq(
            payload.reduce(
              (acc, item) => [...acc, item["create_date:week"]],
              []
            )
          );

          let aptoArr = [];
          let naptoArr = [];
          dateArr.forEach((date) => {
            let apto = 0;
            let napto = 0;
            payload.forEach((item) => {
              if (item["create_date:week"] == date) {
                if (item["apto"] == 1) {
                  apto = apto + (item.__count - 0);
                } else if (item["apto"] == 2) {
                  napto = napto + (item.__count - 0);
                }
              }
            });
            aptoArr.push(apto);
            naptoArr.push(napto);
            this.graph_weeklyLeadsDataLoading = false;
            this.graph_weeklyLeadsData.xAxis[0].categories = [...dateArr];
            this.graph_weeklyLeadsData.series[0].data = [...aptoArr];
            this.graph_weeklyLeadsData.series[1].data = [...naptoArr];
          });
          // console.log("data", "leads :::: ", dateArr);
        } else {
          this.graph_weeklyLeadsDataLoading = false;
        }
      });
    },
    loadMonthlyLeads() {
      this.graph_monthlyLeadsDataLoading = true;
      this.$store.dispatch("a_MonthlyLeadsLoad", {}).then(() => {
        const payload = this.$store.getters.g_monthlyLeadsData;
        if (payload.length) {
          let dateArr = this.$_.uniq(
            payload.reduce(
              (acc, item) => [...acc, item["create_date:month"]],
              []
            )
          );

          let aptoArr = [];
          let naptoArr = [];
          dateArr.forEach((date) => {
            let apto = 0;
            let napto = 0;
            payload.forEach((item) => {
              if (item["create_date:month"] == date) {
                if (item["apto"] == 1) {
                  apto = apto + (item.__count - 0);
                } else if (item["apto"] == 2) {
                  napto = napto + (item.__count - 0);
                }
              }
            });
            aptoArr.push(apto);
            naptoArr.push(napto);
            this.graph_monthlyLeadsDataLoading = false;
            this.graph_monthlyLeadsData.xAxis[0].categories = [...dateArr];
            this.graph_monthlyLeadsData.series[0].data = [...aptoArr];
            this.graph_monthlyLeadsData.series[1].data = [...naptoArr];
          });
          // console.log("data", "leads :::: ", dateArr);
        } else {
          this.graph_monthlyLeadsDataLoading = false;
        }
      });
    },
    loadCallStatusLeads() {
      this.graph_callStatusLeadsDataLoading = true;
      this.$store.dispatch("a_CallStatusLeadsLoad", {}).then(() => {
        const payload = this.$store.getters.g_callStatusLeadsData;
        if (payload.length) {
          const totalCount = payload.reduce((acc, item) => {
            return acc + (item.__count - 0);
          }, 0);
          let index = 0;
          let colorArr = ["#f26083", "#38a4e8", "#ffcc62"];
          let nameArr = {
            1: "Ilocalizado",
            2: "Colabora",
            3: "No colabora",
          };
          const pieData = payload.reduce((acc, item) => {
            let color = colorArr[index];
            index++;
            return [
              ...acc,
              {
                name: nameArr[item.final_status],
                y: item.__count
                  ? parseFloat(((item.__count / totalCount) * 100).toFixed(2))
                  : 0,
                color,
              },
            ];
          }, []);
          // console.log(
          //   "Call Status Leads",
          //   payload,
          //   totalCount,
          //   pieData,
          //   colorArr
          // );
          this.graph_callStatusLeadsDataLoading = false;
          this.callStatusPieChart.series[0].data = [...pieData];
          // console.log("data", "leads :::: ", dateArr);
        } else {
          this.graph_callStatusLeadsDataLoading = false;
        }
      });
    },

    // Social Leads Section
    loadFacebookLeads() {
      this.fbleads_loading = true;
      this.$store.dispatch("a_FacebookLeadsLoad", {}).then(() => {
        const payload = this.$store.getters.g_facebookLeadsData;
        if (payload.length) {
          const count = payload.reduce((acc, item) => {
            return acc + (item.__count - 0);
          }, 0);
          const feeds = payload.reduce((acc, item) => {
            return acc + (item.apto == 1 ? item.__count - 0 : 0);
          }, 0);
          this.fbleads_data = {
            count: helper.kFormatter(count),
            feeds,
          };
          this.fbleads_loading = false;
        } else {
          this.fbleads_loading = false;
        }
      });
    },
    loadLinkedInLeads() {
      this.lnleads_loading = true;
      this.$store.dispatch("a_LinkedInLeadsLoad", {}).then(() => {
        const payload = this.$store.getters.g_linkedinLeadsData;
        if (payload.length) {
          const count = payload.reduce((acc, item) => {
            return acc + (item.__count - 0);
          }, 0);
          const feeds = payload.reduce((acc, item) => {
            return acc + (item.apto == 1 ? item.__count - 0 : 0);
          }, 0);
          this.lnleads_data = {
            count: helper.kFormatter(count),
            feeds,
          };
          this.lnleads_loading = false;
        } else {
          this.lnleads_loading = false;
        }
      });
    },
    loadGoogleAdsLeads() {
      this.galeads_loading = true;
      this.$store.dispatch("a_GoogleAdsLeadsLoad", {}).then(() => {
        const payload = this.$store.getters.g_googleAdsLeadsData;
        if (payload.length) {
          const count = payload.reduce((acc, item) => {
            return acc + (item.__count - 0);
          }, 0);
          const feeds = payload.reduce((acc, item) => {
            return acc + (item.apto == 1 ? item.__count - 0 : 0);
          }, 0);
          this.galeads_data = {
            count: helper.kFormatter(count),
            feeds,
          };
          this.galeads_loading = false;
        } else {
          this.galeads_loading = false;
        }
      });
    },
    loadDev360Leads() {
      this.dv360leads_loading = true;
      this.$store.dispatch("a_Dev360LeadsLoad", {}).then(() => {
        const payload = this.$store.getters.g_dev360LeadsData;
        if (payload.length) {
          const count = payload.reduce((acc, item) => {
            return acc + (item.__count - 0);
          }, 0);
          const feeds = payload.reduce((acc, item) => {
            return acc + (item.apto == 1 ? item.__count - 0 : 0);
          }, 0);
          this.dv360leads_data = {
            count: helper.kFormatter(count),
            feeds,
          };
          this.dv360leads_loading = false;
        } else {
          this.dv360leads_loading = false;
        }
      });
    },
    // Social Leads Ends
    // Lost Reason No
    loadLostReasonNo() {
      this.graph_lostReasonNoDataLoading = true;
      this.$store.dispatch("a_LostReasonNoLoad", {}).then(() => {
        const payload = this.$store.getters.g_lostReasonNoData;
        let resonCountArr = [];
        if (payload.records) {
          let fieldsArr = [
            "languaje",
            "practices",
            "presential",
            "schedule",
            "title",
          ];
          fieldsArr.forEach((fields) => {
            const count = payload.records.reduce((acc, item) => {
              return acc + ((item[fields] == 2 ? 1 : 0) - 0);
            }, 0);
            resonCountArr.push(count);
          });
          // console.log("lost reason no data", payload, resonCountArr);
          this.graph_lostReasonData.series[0].data = [...resonCountArr];
          this.graph_lostReasonNoDataLoading = false;
        } else {
          this.graph_lostReasonNoDataLoading = false;
        }
      });
    },
    // Datatable
    loadDatatable() {
      this.datatableLoads = true;
      this.$store.dispatch("a_DatatableLoad", {}).then(() => {
        const payload = this.$store.getters.g_datatableData;
        let dtTable = [];
        const gradoArr = [
          "MASTER EN CIBERSEGURIDAD",
          "MASTER EN MEDIOAMBIENTE Y GESTION INTELIGENTE DE LA ENERGIA",
          "MASTER EN BIG DATA TECNOLOGIA Y ANALITICA AVANZADA",
        ];
        const criteriaArr = [
          "email1_date",
          "call_done_date",
          "email2_date",
          "email3_date",
        ];
        let degGroupArr = [];
        if (payload.length) {
          this.datatableLoads = false;
          degGroupArr[0] = payload.records.filter((item) => item.degree == 1);
          degGroupArr[1] = payload.records.filter((item) => item.degree == 2);
          degGroupArr[2] = payload.records.filter((item) => item.degree == 3);
          for (var i = 0; i < degGroupArr.length; i++) {
            dtTable = [
              ...dtTable,
              {
                degree: gradoArr[i],
              },
            ];
            criteriaArr.forEach((criteria) => {
              const criteriSum = degGroupArr[i].reduce((acc, item) => {
                return acc + (item[criteria] != false ? 1 : 0);
              }, 0);
              dtTable[i][criteria] = criteriSum;
            });
          }
          this.datatableLoads = false;
          this.items = [...dtTable];
        } else {
          this.datatableLoads = false;
        }
      });
    },
  },
};
</script>
<style>
.degress-col h5 {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.degress-col .table thead td,
.degress-col .table tbody td {
  background-color: #fff !important;
  border: 1px solid #dbdfe3;
}
.degress-col .table tbody td .head {
  font-size: 16px;
  color: #969696;
}
.degress-col .table tbody td p {
  font-size: 15px;
  color: #585858;
}
.degress-item {
  max-height: 300px;
  overflow-y: auto;
  height: 300px;
}

.social-wrapper {
  background-color: #333;
  padding: 5px;
}
.social-wrapper .head {
  background-color: #3b5b95;
  color: #fff;
  text-align: center;
  height: 90px;
}
.social-wrapper .head .c-icon {
  width: 2rem;
  height: 2rem;
  font-size: 2rem;
}
.social-wrapper .body {
  display: flex;
  align-items: center;
  color: #fff;
}
.social-wrapper .body div {
  flex: 1;
  text-align: center;
  flex-basis: 49.5%;
}
.social-wrapper .body div.line {
  background: #8a8a8a;
  height: 25px;
  flex-basis: 0.01%;
}
.social-wrapper .body .sub {
  color: #c2c2c2;
  font-size: 0.7rem;
}
.chart-wrapper {
  box-shadow: 1px 1px 4px 1px #ccc;
}
</style>